<template lang="pug">
TableV2(
  :headers="headers"
  :items="items.results"
  :actions="actions"
  :isLoading="isLoading"
  :pageCount="items.count"
  :currentPage="items.current"
  :querySearch="getListClarificationOfData"
  :defaultFilter="defaultFilter"
)
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'ClarificationOfData',
  data () {
    return {
      headers: [
        { value: 'created_at', text: this.$t('tableHeaders.created_at'), class: 'd-flex align-center justify-start' },
        { value: 'sailor.id', text: this.$t('tableHeaders.sailor_id'), sortable: false },
        { value: 'sailorFullName', text: this.$t('tableHeaders.sailor_full_name_ukr'), sortable: false },
        { value: 'sailorFullNameEng', text: this.$t('tableHeaders.sailor_full_name_eng'), sortable: false },
        { value: 'verificationDocumentTypes', text: this.$i18n.t('tableHeaders.type_document'), sortable: false },
        { value: 'status_document', text: this.$t('tableHeaders.status_statement'), class: 'd-flex align-center justify-start' },
        { value: 'event', class: 'text-center', cellClass: 'text-center', text: this.$i18n.t('actions'), sortable: false }
      ],
      actions: [
        {
          id: 2,
          tooltip: 'tooltip.go',
          to: (item) => ({ name: 'clarification-of-data-info', params: { documentID: item.id }, query: { ...this.$route.query } }),
          target: '_blank',
          color: 'blue',
          name: 'mdi-arrow-right',
          checkAccess: (item) => item.id
        }
      ],
      defaultFilter: {
        status_document: '42',
        page: '1',
        page_size: '10'
      }
    }
  },
  computed: {
    ...mapState({
      items: state => state.statement.clarificationOfData,
      isLoading: state => state.statement.isLoading
    })
  },
  methods: {
    ...mapActions(['getListClarificationOfData'])
  }
}
</script>
